import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { FormattedMessage } from "react-intl"
import { useLocation } from "@reach/router"

import { COLORS, SIZES } from "src/layout/constants"
import { scrollTo } from "src/helpers/scroll"
import Logo from "src/svg/logo.svg"
import Hamburger from "src/components/Hamburger"

const Placeholder = styled.div`
  height: ${SIZES.header}px;
`
const Strip = styled.div`
  background-color: ${COLORS.white};
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1;
  border-bottom: 1px solid ${COLORS.light};
`
const Container = styled.header<{ $scrolled: boolean; $sub: boolean }>`
  display: flex;
  align-items: center;
  transition: height 300ms ease-in-out;
  height: ${SIZES.header}px;
  padding: 5px ${SIZES.mobileMargin}px 0;
  @media (min-width: 1200px) {
    padding: 0 ${SIZES.margin}px ${({ $sub }) => ($sub ? SIZES.headerSubmenu : 0)}px;
    max-width: ${SIZES.maxWidth}px;
    margin: 0 auto;
    height: ${({ $scrolled, $sub }) =>
      ($scrolled ? SIZES.header : SIZES.headerFull) + ($sub ? SIZES.headerSubmenu : 0)}px;
    svg {
      height: 40px;
    }
  }
`
const Nav = styled.nav<{ $open: boolean }>`
  @media (max-width: 1199px) {
    position: absolute;
    top: ${SIZES.header}px;
    right: 0;
    width: 100vw;
    transform: translateX(${({ $open }) => ($open ? 0 : "100vw")});
    transition: transform 300ms ease-in-out;
    height: calc(100vh - ${SIZES.header}px);
    background-color: ${COLORS.dark};
    padding: 0 ${SIZES.mobileMargin}px 100px;
    ul {
      color: ${COLORS.white};
      font-size: 1.75em;
      line-height: 1.75em;
      ul {
        &::before {
          content: "▾";
          font-size: 0.8em;
          display: block;
          margin: -8px 0 -4px;
        }
        font-size: 0.75em;
        font-weight: 300;
        line-height: 24px;
        margin-bottom: 15px;
      }
      .active {
        font-weight: bold;
      }
    }
  }
  @media (min-width: 800px) and (max-width: 1199px) {
    /* limit menu width to 500px on the right on tablets */
    width: 500px;
    transform: translateX(${({ $open }) => ($open ? 0 : "500px")});
  }
  @media (min-width: 1200px) {
    flex: 1;
    display: flex;
    justify-content: space-between;
    ul {
      li {
        display: inline-block;
        padding: 0 10px;
        line-height: 0.75em;
        .active {
          color: ${COLORS.black};
        }
      }
      ul {
        white-space: nowrap;
        position: absolute;
        top: 30px;
        font-size: 0.9em;
      }
    }
  }
`
const MainMenu = styled.ul`
  @media (min-width: 1200px) {
    position: relative;
    margin-left: 15px;
    color: ${COLORS.grey};
    li {
      padding: 0 15px;
    }
    li:not(:last-of-type) {
      border-right: 1px solid ${COLORS.grey};
    }
  }
`
const ExtraMenu = styled.ul`
  @media (max-width: 1199px) {
    position: absolute;
    bottom: 0;
    font-weight: 300;
    li {
      font-size: 0.75em;
      line-height: 1.3em;
    }
  }
  @media (min-width: 1200px) {
    a.active {
      border-bottom: 1px solid ${COLORS.black};
    }
  }
`

const MenuLink = ({ to }: { to: string }) => (
  <li>
    <Link to={`/${to}/`} activeClassName="active" partiallyActive>
      <FormattedMessage id={`menu.${to.replace(/\/([a-z])/, (_, letter) => letter.toUpperCase())}`} />
    </Link>
  </li>
)

const SUBMENUS = {
  products: ["material", "lining", "custom"],
}

interface Props {
  subMenu?: string
  pageTitle?: string
}

const Header: React.FC<Props> = ({ subMenu, pageTitle }) => {
  const { pathname } = useLocation()
  const [menuOpen, setMenuOpen] = useState(false)
  const [height, setHeight] = useState<number>()
  const [scrolled, setScrolled] = useState(false)

  const handleScroll = () => {
    setScrolled(window.scrollY > SIZES.headerFull - SIZES.header)
  }

  const toggleMenu = (open: boolean) => {
    if (open) {
      setTimeout(() => {
        setHeight(window.innerHeight)
      }, 100)
    }
    setMenuOpen(open)
    if (window.innerWidth < 800) {
      document.body.classList[open ? "add" : "remove"]("noscroll")
    }
  }

  const handleMenu = () => {
    toggleMenu(!menuOpen)
  }

  useEffect(() => {
    toggleMenu(false)
    window.addEventListener("scroll", handleScroll, { passive: true })
    return (): void => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <Placeholder>
      <Strip>
        <Container $scrolled={scrolled} $sub={Boolean(subMenu)}>
          <Link to="/">
            <Logo />
          </Link>
          <Hamburger onClick={handleMenu} $active={menuOpen} />
          <Nav $open={menuOpen}>
            <MainMenu>
              <MenuLink to="products" />
              {subMenu === "products" && (
                <ul>
                  {SUBMENUS.products.map((id) => {
                    const text = <FormattedMessage id={"menu." + id} />

                    if (pageTitle) {
                      const page = pathname.split("/")[2]
                      if (page === id) {
                        return (
                          <li key={id}>
                            <Link to="/products/" state={{ id }}>
                              {text}
                            </Link>
                            {" > "}
                            <span className="active">{pageTitle.toLowerCase()}</span>
                          </li>
                        )
                      }

                      return (
                        <li key={id}>
                          <Link to="/products/" state={{ id }}>
                            {text}
                          </Link>
                        </li>
                      )
                    }

                    return (
                      <li key={id}>
                        <a
                          href={"#" + id}
                          onClick={(event) => {
                            event.preventDefault()
                            toggleMenu(false)
                            scrollTo(id)
                          }}
                        >
                          {text}
                        </a>
                      </li>
                    )
                  })}
                </ul>
              )}
              <MenuLink to="inspirations" />
              <MenuLink to="recycled" />
              {subMenu === "recycled" && (
                <ul>
                  <MenuLink to="recycled/textile" />
                  <MenuLink to="recycled/collaboration" />
                </ul>
              )}
              <MenuLink to="about" />
            </MainMenu>
            <ExtraMenu style={height && { bottom: `calc(100vh - ${height}px)` }}>
              <MenuLink to="catalog" />
              <MenuLink to="services" />
              <MenuLink to="samples" />
              <MenuLink to="contact" />
            </ExtraMenu>
          </Nav>
        </Container>
      </Strip>
    </Placeholder>
  )
}

export default Header
