import React from "react"
import styled from "styled-components"

import { COLORS } from "src/layout/constants"

const Button = styled.button<{ $active: boolean }>`
  position: absolute;
  z-index: 12;
  top: 19px;
  right: 25px;
  width: 40px;
  height: 32px;
  padding: 0;
  background: transparent;
  outline: none;
  cursor: pointer;
  @media (min-width: 1200px) {
    display: none;
  }
  span {
    display: block;
    position: absolute;
    height: 3px;
    background: ${COLORS.black};
    opacity: 1;
    left: 5px;
    right: 5px;
    width: 30px;
    transform-origin: left center;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }
  span:nth-of-type(1) {
    top: ${({ $active }) => ($active ? 3.5 : 5)}px;
    ${({ $active }) => ($active ? "transform: rotate(45deg); left: 9px;" : "")}
  }
  span:nth-of-type(2) {
    top: 14px;
    ${({ $active }) => ($active ? "width: 0%; opacity: 0;" : "")}
  }
  span:nth-of-type(3) {
    top: ${({ $active }) => ($active ? 24.5 : 23)}px;
    ${({ $active }) => ($active ? "transform: rotate(-45deg); left: 9px;" : "")}
  }
`

const Hamburger = (props) => (
  <Button {...props}>
    <span />
    <span />
    <span />
  </Button>
)

export default Hamburger
