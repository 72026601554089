import React from "react"
import styled from "styled-components"
import { useIntl } from "react-intl"

import SEO, { Props as SEOProps } from "./seo"
import Header from "./header"
import Footer from "./footer"

const Main = styled.main`
  @media (max-width: 1199px) {
    font-size: 0.8em;
  }
  min-height: 50vh;
`

interface Props extends SEOProps {
  subMenu?: string
  pageTitle?: string
}

const Layout: React.FC<Props> = ({ children, subMenu, pageTitle, title, ...props }) => {
  const { formatMessage } = useIntl()

  if (pageTitle) {
    title = pageTitle.toLowerCase()
  } else if (title) {
    title = formatMessage({ id: `menu.${title}` })
  }

  return (
    <>
      <SEO title={title} {...props} />
      <Header subMenu={subMenu} pageTitle={pageTitle} />
      <Main>{children}</Main>
      <Footer />
    </>
  )
}

export default Layout
