import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { FormattedMessage, useIntl } from "react-intl"

import { COLORS, SIZES } from "src/layout/constants"
import ArrowIcon from "src/svg/arrow.svg"
import PP from "src/svg/pp.svg"

const Container = styled.footer`
  background-color: ${COLORS.dark};
  color: ${COLORS.white};
  position: relative;
`
const Columns = styled.div`
  flex: 2;
  max-width: ${SIZES.maxWidth}px;
  margin: 0 auto;
  display: flex;
  padding: 0 25px;
  @media (max-width: 1099px) {
    display: block;
  }
`
const Column = styled.ul<{ $mobile?: boolean }>`
  padding: 30px 20px;
  @media (max-width: 1099px) {
    display: ${({ $mobile }) => ($mobile ? "block" : "none")};
    padding: 30px 0 0;
  }
  flex: 2;
  &:nth-of-type(2) {
    margin-left: 10%;
  }
  &:last-of-type {
    flex: 0;
    margin-right: 20px;
  }
  li {
    margin: 15px 0;
    white-space: nowrap;
    position: relative;
    svg {
      position: absolute;
      right: -20px;
      top: 1px;
    }
  }
`
const Form = styled.form`
  border-bottom: 1px solid ${COLORS.white};
  display: flex;
  padding-bottom: 7px;
  input {
    flex: 1;
    padding: 0 0 7px;
    min-width: 200px;
    color: ${COLORS.white};
    &::placeholder {
      color: ${COLORS.white};
    }
  }
  button {
    padding: 1px 0 4px 10px;
  }
`
const Bottom = styled(Columns)`
  li {
    margin: 10px 0;
    margin-right: 50px;
  }
  padding-bottom: 30px;
  @media (min-width: 1100px) {
    padding: ${SIZES.margin}px;
  }
  position: relative;
`
const Logo = styled(PP)`
  fill: ${COLORS.white};
  width: 120px;
  margin: 14px -14px;
  @media (min-width: 1100px) {
    position: absolute;
    right: 44px;
    bottom: 27px;
  }
`

const YEAR = new Date().getFullYear()

const Footer: React.FC = () => {
  const { formatMessage } = useIntl()

  return (
    <Container>
      <Columns>
        <Column $mobile>
          <li>
            <FormattedMessage id="menu.newsletter" tagName="strong" />
          </li>
          <Form
            method="post"
            action="https://premicesandco.us10.list-manage.com/subscribe/post?u=0dc8000d6b2d9e40b58f8bce4&amp;id=4ac38afa11"
          >
            <input type="email" name="EMAIL" placeholder={formatMessage({ id: "menu.newsletterPlaceholder" })} />
            <button type="submit">
              <ArrowIcon />
            </button>
          </Form>
        </Column>
        <Columns>
          <Column>
            <li>
              <Link to="/products/">
                <FormattedMessage id="menu.products" />
              </Link>
            </li>
            <li>
              <Link to="/inspirations/">
                <FormattedMessage id="menu.inspirations" />
              </Link>
            </li>
          </Column>
          <Column>
            <li>
              <Link to="/recycled/">
                <FormattedMessage id="menu.recycled" />
              </Link>
            </li>
            <li>
              <Link to="/about/">
                <FormattedMessage id="menu.about" />
              </Link>
            </li>
          </Column>
        </Columns>
        <Column>
          <li>
            <Link to="/catalog/">
              <FormattedMessage id="menu.catalog" />
            </Link>
          </li>
          <li>
            <Link to="/services/">
              <FormattedMessage id="menu.services" />
            </Link>
          </li>
          <li>
            <Link to="/contact/">
              <FormattedMessage id="menu.contact" />
            </Link>
          </li>
        </Column>
        <Column>
          <li>
            <a href="https://www.instagram.com/pierreplume.fr/">
              Instagram
              <ArrowIcon />
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/pierreplume.fr/">
              Facebook <ArrowIcon />
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/pierreplume/">
              LinkedIn <ArrowIcon />
            </a>
          </li>
        </Column>
      </Columns>
      <Bottom>
        <Logo />
        <li>
          <strong>© pierreplume {YEAR}</strong>
        </li>
        <li>
          <Link to="/legal/">
            <FormattedMessage id="menu.legal" />
          </Link>
        </li>
      </Bottom>
    </Container>
  )
}

export default Footer
